import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {CgTwitter, FaLinkedinIn, GrMedium, IoMdHand, SiStackoverflow} from "react-icons/all";
import {useForm} from "react-hook-form";
import emailjs from 'emailjs-com';
import {getEmailJSCredentials} from "../../helpers/EmailConfig";
import {useToasts} from "react-toast-notifications";

const NMContact: React.FC = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [reason, setReason] = useState('');
    const [message, setMessage] = useState('');
    const [isNameFocused, setNameFocused] = useState(false);
    const [isEmailFocused, setEmailFocused] = useState(false);
    const [isReasonFocused, setReasonFocused] = useState(false);
    const [isMessageFocused, setMessageFocused] = useState(false);

    const [sending, setSending] = useState<boolean>(false);

    const {addToast} = useToasts();

    interface IFormInputs {
        name: string
        email: string
        reasonFor: string
        messageString: string
    }

    const {
        register,
        handleSubmit,
    } = useForm<IFormInputs>();

    const nameRef = useRef<HTMLInputElement | null>(null);
    const emailRef = useRef<HTMLInputElement | null>(null);
    const reasonForRef = useRef<HTMLInputElement | null>(null);
    const messageStringRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        nameRef.current?.addEventListener('focus', () => setNameFocused(true));
        nameRef.current?.addEventListener('blur', () => setNameFocused(false));

        return () => {
            nameRef.current?.removeEventListener('focus', () => setNameFocused(true));
            nameRef.current?.removeEventListener('blur', () => setNameFocused(false));
        }
    }, []);

    useEffect(() => {
        emailRef.current?.addEventListener('focus', () => setEmailFocused(true));
        emailRef.current?.addEventListener('blur', () => setEmailFocused(false));

        return () => {
            emailRef.current?.removeEventListener('focus', () => setEmailFocused(true));
            emailRef.current?.removeEventListener('blur', () => setEmailFocused(false));
        }
    }, []);

    useEffect(() => {
        reasonForRef.current?.addEventListener('focus', () => setReasonFocused(true));
        reasonForRef.current?.addEventListener('blur', () => setReasonFocused(false));

        return () => {
            reasonForRef.current?.removeEventListener('focus', () => setReasonFocused(true));
            reasonForRef.current?.removeEventListener('blur', () => setReasonFocused(false));
        }
    }, []);

    useEffect(() => {
        messageStringRef.current?.addEventListener('focus', () => setMessageFocused(true));
        messageStringRef.current?.addEventListener('blur', () => setMessageFocused(false));

        return () => {
            messageStringRef.current?.removeEventListener('focus', () => setMessageFocused(true));
            messageStringRef.current?.removeEventListener('blur', () => setMessageFocused(false));
        }
    }, []);

    const registeredName = register('name', {required: true});
    const registeredEmail = register('email', {required: true});
    const registeredReasonFor = register('reasonFor', {required: true});
    const registeredMessageString = register('messageString', {required: true});

    const onValidSubmit = () => {

        const credentials = getEmailJSCredentials();

        setSending(true);
        emailjs.send(
            credentials.emailJSServiceId,
            credentials.emailJSTemplateId,
            {
                to_name: 'Neminda',
                from_name: name + ' ' + email,
                reason: reason,
                message: message
            },
            credentials.emailJSUserId
        )
            .then((result) => {
                setSending(false);
                addToast('Successfully sent', {appearance: 'success', autoDismiss: true});
            }, (error) => {
                setSending(false);
                addToast('Error please try again', {appearance: 'error', autoDismiss: true});
            });
    }

    return (
        <div className='nm-contact'>
            <Row className='contact-me-row'>
                <Col className='contact-me-col mx-auto my-auto'>
                    <Row>
                        <Col className='form-contact-col' sm={12} md={{span: 5}}>

                            <IoMdHand
                                className='greeting-hand'
                            />
                            <h6 className='greeting-text mt-3'>Hello, Let's get in touch.</h6>
                            <Form className='mt-5' onSubmit={handleSubmit(onValidSubmit)}
                            >
                                <Form.Label className='form-label-active'>Name</Form.Label>
                                <Form.Control
                                    {...registeredName}
                                    ref={(e: HTMLInputElement) => {
                                        registeredName.ref(e)
                                        nameRef.current = e
                                    }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <div className={isNameFocused ? 'input-underline-active' : 'input-underline'}/>
                                <Form.Label className='form-label-active mt-5'>Email</Form.Label>
                                <Form.Control
                                    {...registeredEmail}
                                    ref={(e: HTMLInputElement) => {
                                        registeredEmail.ref(e)
                                        emailRef.current = e
                                    }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <div className={isEmailFocused ? 'input-underline-active' : 'input-underline'}/>
                                <Form.Label className='form-label-active mt-5'>Reason for contact</Form.Label>
                                <Form.Control
                                    {...registeredReasonFor}
                                    ref={(e: HTMLInputElement) => {
                                        registeredReasonFor.ref(e)
                                        reasonForRef.current = e
                                    }}
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                                <div className={isReasonFocused ? 'input-underline-active' : 'input-underline'}/>
                                <Form.Label className='form-label-active mt-5'>Message</Form.Label>
                                <Form.Control
                                    {...registeredMessageString}
                                    ref={(e: HTMLInputElement) => {
                                        registeredMessageString.ref(e)
                                        messageStringRef.current = e
                                    }}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <div className={isMessageFocused ? 'input-underline-active' : 'input-underline'}/>
                                <div className='float-right'>
                                    <Button
                                        className='mt-5'
                                        type='submit'
                                    >
                                        Send message
                                        {sending
                                            ?
                                            <Spinner
                                                animation="border"
                                                size={'sm'}
                                                style={{
                                                    position: 'absolute',
                                                    top: '28%',
                                                    left: '95%'
                                                }}
                                            />
                                            :
                                            ''
                                        }
                                        <div className='btn-underline mt-2'/>
                                    </Button>
                                </div>
                            </Form>

                        </Col>
                        <Col className='details-col' sm={12} md={6}>

                            <div className='eq-height-container'>
                                <div className='eq-height-row'>
                                    <div>
                                        <h6 className='nm-detail-title mb-4'>In the good old area?</h6>
                                        <h6>1/395 Vivekarama Road</h6>
                                        <h6>Vilegoda Kalutara</h6>
                                        <h6>12000 Sri Lanka</h6>
                                    </div>
                                </div>
                                <div className='eq-height-row middle-content'>
                                    <div>
                                        <h6 className='nm-detail-title mb-4'>Working Hours</h6>
                                        <h6>Monday-Saturday</h6>
                                        <h6>11am - 11pm</h6>
                                        <h6>Sunday</h6>
                                        <h6>11am - 11pm</h6>
                                    </div>
                                </div>
                                <div className='eq-height-row end-content'>
                                    <div>
                                        <h6 className='nm-detail-title mb-4'>Contact</h6>
                                        <h6>+94 713 787 288</h6>
                                        <h6>nemindaprabhashwara@gmail.com</h6>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='connect-me-row px-sm-5'>
                <Col className='connect-me-col mt-4 mt-md-5 px-4 px-sm-5'>
                    <span className='connect-me-text'>Connect with me</span>
                    <div className='connect-me-line'/>
                    <div className='icon-container'>
                        <a className='p-0 m-0' href={'https://twitter.com/NemindaP'} target='_blank' rel="noopener noreferrer">
                            <CgTwitter
                                color={'white'}
                                className='mr-3 connect-me-icon'
                            />
                        </a>
                        <a className='p-0 m-0' href={'https://www.linkedin.com/in/neminda-piyasena/'} target='_blank' rel="noopener noreferrer">
                            <FaLinkedinIn
                                color={'white'}
                                className='mr-3 connect-me-icon'
                            />
                        </a>
                        <a className='p-0 m-0' href={'https://stackoverflow.com/users/12272450/neminda-prabhashwara'} target='_blank' rel="noopener noreferrer">
                            <SiStackoverflow
                                color={'white'}
                                className='mr-3 connect-me-icon'
                            />
                        </a>
                        <a className='p-0 m-0' href={'https://neminda99prabhashwara.medium.com/'} target='_blank' rel="noopener noreferrer">
                            <GrMedium
                                color={'white'}
                                className='mr-3 connect-me-icon'
                            />
                        </a>
                    </div>

                </Col>
            </Row>
        </div>

    );

};

export default NMContact;