import React from 'react';
import './assets/styles/main.scss';
import NMPortfolioView from "./components/view/NMPortfolioView";

function App() {
    return (
        <NMPortfolioView/>
    );
}

export default App;
