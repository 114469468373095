import React from 'react';
import {FiExternalLink} from "react-icons/all";
import {IProblemDefinition} from "../../types/ProjectType";

interface NMProblemDefinitionProps extends IProblemDefinition {

}

const NMSystemImplementation: React.FC<NMProblemDefinitionProps> = (props: NMProblemDefinitionProps) => {

    return (
        <div className='eq-height-container w-100'>
            <div className='eq-height-row middle-content mt-5 xs-end-content'>
                <h4>
                    Implementation Details
                </h4>
            </div>
            <div className='eq-height-row text-center mt-4 mt-md-0 xs-middle-content'>
                <h6>
                    {props.implementation}
                </h6>
            </div>
            <div className='eq-height-row justify-content-end d-flex md-end-content xs-middle-content'>
                {props.link ?
                    <a className='project-demo-link' href={props.link} target='_blank' rel="noopener noreferrer">
                        Demo
                        <FiExternalLink className='external-link-icon'/>
                    </a> :
                    <span className='not-hosted'>Not hosted yet</span>
                }

            </div>
        </div>
    );

};

export default NMSystemImplementation;