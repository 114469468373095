import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/all";
import NMProjectNav from "./NMProjectNav";
import NMImageColumn from "./NMImageColumn";
import NMDescriptionColumn from "./NMDescriptionColumn";
import IProject from "../../types/ProjectType";
import {useHistory} from 'react-router-dom'

interface NMWorkProps {
    project: IProject
    nextProject: string | null
    previousProject: string | null
}

const NMWork: React.FC<NMWorkProps> = (props: NMWorkProps) => {

    const [dotNumber, setDotNumber] = useState<number>(1);
    const {push} = useHistory();

    const [animationStatus, setAnimationStatus] = useState<number>(0);
    const workRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const ref = workRef.current;
        ref?.addEventListener('animationend', () => setAnimationStatus(1));
        return () => ref?.removeEventListener('animationend', () => setAnimationStatus(1));
    }, []);

    return (
        <div className='nm-work' ref={workRef}>
            <Row className='nm-work-row'>
                <Col className='nm-work-col my-auto'>
                    <Row className='project-area-row mx-auto'>
                        <Col className='project-area-col'>
                            <NMProjectNav
                                setDotNumber={setDotNumber}
                                activeDotNumber={dotNumber}
                                animationStatus={animationStatus}
                            />
                            <Row className='content-row'>
                                <NMImageColumn
                                    image={props.project.image}
                                />
                                <NMDescriptionColumn
                                    dotNumber={dotNumber}
                                    project={props.project}
                                />
                            </Row>
                            <Row className='align-items-center justify-content-center mt-md-2 project-button-row'>
                                <Col className='text-danger text-center'>
                                    <Button
                                        disabled={props.previousProject === null}
                                        onClick={() => {
                                            setDotNumber(1);
                                            push(`/work/${props.previousProject}`);
                                        }}
                                    >
                                        <AiOutlineArrowLeft
                                            className='mr-1'
                                        />
                                        Previous project
                                    </Button>
                                </Col>
                                <Col className='text-danger text-center'>
                                    <Button
                                        disabled={props.nextProject === null}
                                        onClick={() => {
                                            setDotNumber(1);
                                            push(`/work/${props.nextProject}`);
                                        }}
                                    >
                                        Next project
                                        <AiOutlineArrowRight
                                            className='ml-1'
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

};

export default NMWork;