import React from 'react';
import {FiExternalLink} from "react-icons/all";
import {ISystemTechnology} from "../../types/ProjectType";

interface NMSystemTechnologyProps extends ISystemTechnology {

}

const NMSystemTechnology: React.FC<NMSystemTechnologyProps> = (props: NMSystemTechnologyProps) => {

    const combineString = (list: string[]) => {
        let techStack = '';
        for (let technology of list) {
            techStack += techStack === '' ? technology : ' | ' + technology;
        }
        return techStack;
    }

    const getList = (name: string) => {
        switch (name) {
            case 'frontend':
                return combineString(props.technology.frontend);
            case 'backend':
                return combineString(props.technology.backend);
            case 'api':
                return props.technology.api;
            default:
                return '';
        }
    }

    return (
        <div className='eq-height-container w-100'>
            <div className='eq-height-row middle-content mt-5 xs-end-content'>
                <h4>
                    Technology
                </h4>
            </div>
            <div className='eq-height-row mt-4 mt-md-0'>
                <h6>
                    Frontend: {getList('frontend')}
                </h6>
                <h6>
                    Backend: {getList('backend')}
                </h6>
                <h6>
                    Integration: {getList('api')}
                </h6>
            </div>
            <div className='eq-height-row justify-content-end d-flex md-end-content xs-middle-content'>
                {props.link ?
                    <a className='project-demo-link' href={props.link} target='_blank' rel="noopener noreferrer">
                        Demo
                        <FiExternalLink className='external-link-icon'/>
                    </a> :
                    <span className='not-hosted'>Not hosted yet</span>
                }
            </div>
        </div>
    );

};

export default NMSystemTechnology;