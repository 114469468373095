import IProject from "../../types/ProjectType";
import ShoppingCart from '../images/ShoppingCart.jpg';
import SCRUTINIZER from '../images/SCRUTINIZER.png';
import JobAdvertisingPlatform from '../images/JobAdvertisingPlatform.jpg';

const project: IProject[] = [
    {
        image: ShoppingCart,
        link: 'https://store.thecloudhemisphere.com/',
        name: 'ShoppingCart',
        overview:
            'In this platform you can search your products by selecting the categories.' +
            'After the browsing is completed you can navigate to the checkout page and ' +
            'you will see your cart there. Also, you can see your cart while shopping by clicking ' +
            'the cart dropdown. Then you can enter your payment details. No need to login you can just ' +
            'place your order. As an admin you have reliable responsive interface for managing your products, orders.',
        implementation:
            'The project is using React and sass as the frontend technology. Git is utilized as the source control. ' +
            'For the backend Express, GraphQL are used and mongodb is used as the database. The infrastructure is ' +
            'provisioned in AWS.',
        technology: {
            frontend: ['React', 'Redux', 'Bootstrap', 'Typescript'],
            backend: ['Express', 'GraphQL', 'Typescript', 'MongoDB'],
            api: 'Apollo GraphQL'
        }
    },
    {
        image: SCRUTINIZER,
        link: 'https://forum.thecloudhemisphere.com/',
        name: 'THE SCRUTINIZER',
        overview:
            'This platform is build as a poc for Q & A  project. You can post your question as a logged in user and ' +
            'the others users or yourself can answer to that question. Meanwhile, commenting for questions and answers  ' +
            'is also available. This is built keeping in mind the functionality of stackoverflow. Further development ' +
            'is ongoing to add more additional features like analytics on user interactions. However, this project is to ' +
            'showcase my skills and not for going production. If you need a product like this I\'m willing to build it ' +
            'for you let\'s connect and do it.',
        implementation:
            'The project is using React and sass as the frontend technology. Git is utilized as the source control. ' +
            'For the backend Express, GraphQL are used and postgreSQL is used as the database. The infrastructure is ' +
            'provisioned in AWS.',
        technology: {
            frontend: ['React', 'Redux', 'Bootstrap', 'Typescript'],
            backend: ['Express', 'GraphQL', 'Typescript', 'PostgreSQL'],
            api: 'Apollo GraphQL'
        }
    },
    {
        image: JobAdvertisingPlatform,
        link: null,
        name: 'JobAdvertisingPlatform',
        overview:
            'This site is based on four job categories namely, Part time, Full Time, ' +
            'Intern and Temporary. Also, it can post job vacancies for all of districts ' +
            'in Sri Lanka. There is a wide variety of job types in the list. All ' +
            'these jobs are searchable according to your category selections. As a company, ' +
            'one can post their job vacancies free of charge in this platform. They just ' +
            'have to verify their mail and they are the driving wheels of this site.',
        implementation:
            'The project is using React and sass as the frontend technology. Git is utilized as the source control. ' +
            'For the backend SpringBoot is used and MySQL is used as the database. The infrastructure is ' +
            'provisioned in AWS. But is scaled down to cost issues :)!',
        technology: {
            frontend: ['React', 'Redux', 'Bootstrap', 'JavaScript'],
            backend: ['SpringBoot', 'Java'],
            api: 'Traditional REST Api'
        }
    }

];

export default project;

