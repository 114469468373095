import React from 'react';
import {Row, Col, Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const NMHome: React.FC = () => {

    const {push} =useHistory();

    return (
            <Row className='nm-home'>
                <Col className='my-auto'>
                    <Row className='nm-opening-tag'>
                        <Col lg={{offset: 3}} md={{offset: 2}} xs={{offset: 1}}>
                            <span className='home-code'>`$</span>
                        </Col>
                    </Row>
                    <br/>
                    <Row className='nm-opening-brace'>
                        <Col lg={{offset: 3}} md={{offset: 2}} xs={{offset: 1}}>
                            <span className='home-code'>{'{'}</span>
                        </Col>
                    </Row>
                    <br/>
                    <Row className='nm-section'>
                        <Col lg={{offset: 3}} md={{offset: 2}} xs={{offset: 1}}>
                            <h1 className='nm-greeting'>Hi,</h1>
                            <h1 className='nm-intro'>I'm <span className='nm-name'>Neminda,</span></h1>
                            <h1 className='nm-interest'>Cloud Engineer</h1>
                        </Col>
                    </Row>
                    <br/>
                    <Row className='nm-closing-brace'>
                        <Col lg={{offset: 3}} md={{offset: 2}} xs={{offset: 1}}>
                            <span className='home-code'>{'}'}</span>
                        </Col>
                    </Row>
                    <br/>
                    <Row className='nm-closing-tag'>
                        <Col lg={{offset: 3}} md={{offset: 2}} xs={{offset: 1}}>
                            <span className='home-code'>`</span><br/>
                        </Col>
                    </Row>
                    <br/>
                    <Row className='nm-btn'>
                        <Col lg={{offset: 3}} md={{offset: 2}} xs={{offset: 1}}>
                            <Button
                                className='py-2 px-3'
                                onClick={() => {
                                    push('/work');
                                }}
                            >
                                my work
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
);

};

export default NMHome;