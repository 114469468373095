import React from 'react';
import NMSystemTechnology from "./NMSystemTechnology";
import {Col} from "react-bootstrap";
import IProject from "../../types/ProjectType";
import NMSystemOverview from "./NMSystemOverview";
import NMSystemImplementation from "./NMSystemImplementation";

interface NMDescriptionColumnProps {
    dotNumber: number
    project: IProject
}

const NMDescriptionColumn: React.FC<NMDescriptionColumnProps> = (props: NMDescriptionColumnProps) => {

    switch (props.dotNumber) {
        case 1:
            return (
                <Col className='px-5 project-description' xs={12} md={6}>
                    <NMSystemOverview
                        overview={props.project.overview}
                        link={props.project.link}
                    />
                </Col>
            );
        case 2:
            return (
                <Col className='px-5 project-description' xs={12} md={6}>
                    <NMSystemImplementation
                        implementation={props.project.implementation}
                        link={props.project.link}
                    />
                </Col>
            );
        case 3:
            return (
                <Col className='px-5 project-description' xs={12} md={6}>
                    <NMSystemTechnology
                        technology={props.project.technology}
                        link={props.project.link}
                    />
                </Col>
            );
        default:
            return (
                <Col className='px-5 project-description' xs={12} md={6}>
                    <NMSystemOverview
                        overview={props.project.overview}
                        link={props.project.link}
                    />
                </Col>
            );
    }

};

export default NMDescriptionColumn;