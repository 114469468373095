import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import NMNotice from "./NMNotice";

const NMTechStack: React.FC = () => {
    const [divStyle, setDivStyle] = useState<CSSProperties>({});

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const updateMousePosition = (ev: MouseEvent) => {
            setDivStyle({
                ...divStyle,
                transform: 'rotateY(' + Math.sqrt(Math.pow(ev.clientX, 2) + Math.pow(ev.clientY, 2)) + 'deg)',
            });
        };

        const ref = divRef.current;
        if (divRef.current) {
            divRef.current.addEventListener("mousemove", updateMousePosition);
        }
        return () => ref?.removeEventListener("mousemove", updateMousePosition);
    }, [divStyle]);

    return (
        <div ref={divRef}>
            <Row className='nm-tech-stack'>
                <Col className='nm-tech-stack-col my-auto text-center'>
                    <Row>
                        <Col>
                            <div style={divStyle}>
                                <span>React</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>GraphQL</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>JS</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>TS</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>SpringBoot</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>Express</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col>
                            <div style={divStyle}>
                                <span>Node</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>SASS</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>HTML</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>CSS</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col>
                            <div style={divStyle}>
                                <span>C</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>Java</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>Python</span>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col>
                            <div style={divStyle}>
                                <span>MongoDB</span>
                            </div>
                        </Col>
                        <Col>
                            <div style={divStyle}>
                                <span>MySQL</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    );

};

export default NMTechStack;