import React from 'react';
import {Container} from "react-bootstrap";
import NMHome from "../home/NMHome";
import NMNavigation from "../navigation/NMNavigation";
import NMAbout from "../about/NMAbout";
import NMContact from "../contact/NMContact";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import NMWorkDashBoard from "../work/NMWorkDashBoard";
import NMTechStack from "../about/NMTechStack";
import {ToastProvider} from "react-toast-notifications";

const NMPortfolioView: React.FC = () => {


    return (
        <React.Fragment>
            <ToastProvider>
                <Router>
                    <NMNavigation/>
                    <Container fluid={true}>
                        <Switch>
                            <Route
                                path='/'
                                exact={true}
                                component={NMHome}
                            />
                            <Route
                                exact={true}
                                path='/about'
                                component={NMAbout}
                            />
                            <Route
                                exact={true}
                                path='/skills'
                                component={NMTechStack}
                            />
                            <Route
                                exact={true}
                                path='/contact'
                                component={NMContact}
                            />
                            <Route
                                path='/work'
                                component={NMWorkDashBoard}
                            />
                            <Route
                                path='*'
                                render={() => {
                                    return (
                                        <Redirect
                                            to='/'
                                        />
                                    );
                                }}
                            />
                        </Switch>
                    </Container>
                </Router>
            </ToastProvider>
        </React.Fragment>
    );

};

export default NMPortfolioView;