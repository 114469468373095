export const getEmailJSCredentials = (): {emailJSUserId: string, emailJSTemplateId: string, emailJSServiceId: string} => {
    const emailJSUserId: string = process.env.REACT_APP_EMAILJS_USER_ID || '';
    const emailJSTemplateId: string = process.env.REACT_APP_EMAILJS_TEMPLATE_ID || '';
    const emailJSServiceId: string = process.env.REACT_APP_EMAILJS_SERVICE_ID || '';

    return {
        emailJSUserId,
        emailJSTemplateId,
        emailJSServiceId
    }
}