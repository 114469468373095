import React, {useEffect, useRef, useState} from 'react';
import useWindowDimensions from "./useWindowDimentions";
import {Col, Row} from "react-bootstrap";
import {FaRegDotCircle} from "react-icons/all";

interface NMProjectNavProps {
    setDotNumber: (value: number) => void
    activeDotNumber: number
    animationStatus: number
}

const NMProjectNav: React.FC<NMProjectNavProps> = (props: NMProjectNavProps) => {

    const [firstDot, setFirstDot] = useState<{ x: number | null, y: number | null }>({
        x: null,
        y: null
    });
    const [secondDot, setSecondDot] = useState<{ x: number | null, y: number | null }>({
        x: null,
        y: null
    });
    const [thirdDot, setThirdDot] = useState<{ x: number | null, y: number | null }>({
        x: null,
        y: null
    });

    const firstDotRef = useRef<HTMLSpanElement | null>(null);
    const secondDotRef = useRef<HTMLSpanElement | null>(null);
    const thirdDotRef = useRef<HTMLSpanElement | null>(null);

    const {width} = useWindowDimensions();

    useEffect(() => {
        if (firstDotRef.current) {
            setFirstDot({
                x: firstDotRef.current.getBoundingClientRect().x,
                y: firstDotRef.current.getBoundingClientRect().y
            });
        }
        if (secondDotRef.current) {
            setSecondDot({
                x: secondDotRef.current.getBoundingClientRect().x,
                y: secondDotRef.current.getBoundingClientRect().y
            });
        }
        if (thirdDotRef.current) {
            setThirdDot({
                x: thirdDotRef.current.getBoundingClientRect().x,
                y: thirdDotRef.current.getBoundingClientRect().y
            });
        }
    }, [width, props.animationStatus]);

    return (
        <Row className='navigation-row text-center align-items-center justify-content-center'>
            <Col>
                <span
                    ref={firstDotRef}
                >
                    <FaRegDotCircle
                        className={props.activeDotNumber === 1 ? 'active-icon-dot': 'icon-dot'}
                        onClick={() => props.setDotNumber(1)}
                    />
                </span>
            </Col>
            <div
                style={{
                    width: Number(secondDot.x) - Number(firstDot.x),
                    left: (Number(secondDot.x) - Number(firstDot.x)) / 2
                }}
                className='nav-line-1'
            />
            <Col>
                <span
                    ref={secondDotRef}
                >
                    <FaRegDotCircle
                        className={props.activeDotNumber === 2 ? 'active-icon-dot': 'icon-dot'}
                        onClick={() => props.setDotNumber(2)}
                    />
                </span>
            </Col>
            <div
                style={{
                    width: Number(thirdDot.x) - Number(secondDot.x),
                    right: (Number(thirdDot.x) - Number(secondDot.x)) / 2
                }}
                className='nav-line-2'
            />
            <Col>
                <span
                    ref={thirdDotRef}
                >
                    <FaRegDotCircle
                        className={props.activeDotNumber === 3 ? 'active-icon-dot': 'icon-dot'}
                        onClick={() => props.setDotNumber(3)}

                    />
                </span>
            </Col>
        </Row>
    );

};

export default NMProjectNav;