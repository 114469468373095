import React, {useState} from 'react';
import {Image, Navbar} from "react-bootstrap";
import Logo from '../../assets/vectors/logo-neminda.svg';
import {Link, useHistory} from "react-router-dom";

const NMNavigation: React.FC = () => {

    const [pathname, setPathname] = useState('');
    const history = useHistory();
    history.listen((location) => {
        setPathname(location.pathname);
    });

    return (
        <Navbar className='nm-nav-bar' expand='sm'>

            <Link
                to='/'
                className='nav-link'
            >
                <Navbar.Brand className='d-block d-sm-none'>
                    <Image src={Logo} className='img-fluid nm-logo'/>
                </Navbar.Brand>
            </Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav" className='ml-auto'/>

            <Navbar.Collapse id="basic-navbar-nav" className='justify-content-between w-100 mx-auto text-right'>

                <Link
                    to='/work/ShoppingCart'
                    className='nav-link'
                >
                    Work
                    <div className={pathname.substring(0, 5) === '/work' ? 'active-hover-line' : 'hover-line'}/>
                </Link>

                <Link
                    to='/'
                    className='nav-link'
                >
                    Resume
                    <div className='hover-line'/>
                </Link>

                <Link
                    to='/'
                    className='nav-link'
                >
                    <Navbar.Brand className='d-none d-sm-block'>
                        <Image src={Logo} className='img-fluid nm-logo'/>
                    </Navbar.Brand>
                </Link>

                <Link
                    to='/about'
                    className='nav-link'
                >
                    About
                    <div className={pathname === '/about' ? 'active-hover-line' : 'hover-line'}/>
                </Link>

                <Link
                    to='/contact'
                    className='nav-link'
                >
                    Contact
                    <div className={pathname === '/contact' ? 'active-hover-line' : 'hover-line'}/>
                </Link>

            </Navbar.Collapse>
        </Navbar>
    );

};

export default NMNavigation;