import React from 'react';
import {Col, Image} from "react-bootstrap";

interface NMImageColumnProps {
    image: string
}

const NMImageColumn: React.FC<NMImageColumnProps> = (props: NMImageColumnProps) => {

    return (
        <Col className='my-auto' xs={12} md={6}>
            <div className='image-container'>
                <Image src={props.image} className='img-fluid image'/>
                <div className='image-shadow'/>
            </div>
        </Col>
    );

};

export default NMImageColumn;