import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import NMWork from "./NMWork";
import projects from "../../assets/data/projects";
import IProject from "../../types/ProjectType";

const NMWorkDashBoard: React.FC = () => {

    const findProjectIndex = (name: string) => {
        return projects.findIndex((project: IProject) => {
            return project.name === name;
        });
    }

    const getProject = (name: string) => {
        let projectIndex: number = findProjectIndex(name);
        let project: IProject;
        if (projectIndex === -1) {
            project = projects[0];
        } else {
            project = projects[projectIndex]
        }
        return {project, projectIndex};
    }

    return (
        <Switch>
            <Route
                path='/work/:projectName'
                render={(props) => {
                    const {project, projectIndex}= getProject(props.match.params.projectName)
                    if (projectIndex === -1) {
                        return (
                            <Redirect
                                to={'/work/ShoppingCart'}
                            />
                        );

                    } else {
                        return (
                            <NMWork
                                project={project}
                                previousProject={projectIndex !== 0 ? projects[projectIndex - 1].name : null}
                                nextProject={projectIndex !== (projects.length - 1) ? projects[projectIndex + 1].name : null}
                            />
                        );
                    }
                }}
            />
            <Route
                path='*'
                render={() => {
                    return (
                        <Redirect
                            to={'/work/ShoppingCart'}
                        />
                    );
                }}
            />
        </Switch>
    );

};

export default NMWorkDashBoard;