import React from 'react';
import {Button, Col, Image, Row} from "react-bootstrap";
import Neminda from '../../assets/images/Neminda.png';
import {useHistory} from "react-router-dom";

const NMAbout: React.FC = () => {

    const {push} = useHistory();

    return (
        <Row className='nm-about'>
            <Col className='about-col mx-auto'>
                <Row className='row-1-personal'>
                    <Col className='col-1-text my-auto' sm={10} md={8} xl={6}>
                        <h1 className='nm-intro'><span className='nm-name'>Neminda</span></h1>
                        <h1 className='nm-intro'><span className='nm-name'>Prabhashwara</span></h1>
                        <Row className='inner-row mt-5'>
                            <Col className='inner-row-col-1-graduation' sm={8} md={8}>
                                <h5>Certified Kubernetes Administrator</h5>
                            </Col>
                            <Col className='inner-row-col-2-university' sm={4} md={4}>
                                <h5>Software Engineering (Bsc, Hons)</h5>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='col-2-img my-auto' sm={2} md={4} xl={6}>
                        <Image src={Neminda} alt='a picture of neminda' className='nm-image float-right'/>
                    </Col>
                </Row>
                <Row className='nm-btn text-center'>
                    <Col>
                        <Button
                            className='py-2 px-3'
                            onClick={() => {
                                push('/skills');
                            }}
                        >
                            my tech stack
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

};

export default NMAbout;